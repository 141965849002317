<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx" v-if="dataLoading">
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-card-title class="primary--text">
        Fuel History
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-title
        class="justify-center justify-sm-start"
        v-if="
          $_checkPermission(
            this.auth.permissions,
            'Manage Vehicle Fuel',
            'Read All'
          ) ||
          $_checkPermission(
            this.auth.permissions,
            'Manage Vehicle Fuel',
            'Read Sub'
          )
        "
      >
        <v-card outlined>
          <v-tabs
            v-model="tab"
            @change="changeView"
            background-color="transparent"
          >
            <v-tooltip bottom max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="1"
                  >My</v-tab
                >
              </template>
              <span>View your fuel logs</span>
            </v-tooltip>
            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Fuel',
                  'Read Sub'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Fuel',
                  'Read Sub'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="3"
                  >Sub</v-tab
                >
              </template>
              <span>View fuel logs of your subordinates</span>
            </v-tooltip>

            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Fuel',
                  'Read All'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Fuel',
                  'Read All'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="2"
                  >All</v-tab
                >
              </template>
              <span>View all fuel logs</span>
            </v-tooltip>
          </v-tabs>
        </v-card>
      </v-card-title>
      <v-card-title class="primary--text">
        <v-col style="min-width: 200px">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
            v-model="menu1"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                filled
                flat
                outlined
                label="From (Date)"
                v-model="startDate"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                clearable
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              color="primary"
              no-title
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col style="min-width: 200px">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
            v-model="menu2"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                filled
                flat
                outlined
                label="To (Date)"
                v-model="endDate"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                clearable
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              color="primary"
              no-title
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="vehicleUuids"
            :items="vehicles"
            item-text="vehicle_no"
            item-value="uuid"
            label="Vehicles"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col style="min-width: 200px">
          <v-autocomplete
            filled
            multiple
            flat
            v-model="staffUuids"
            :items="staff"
            item-text="name"
            item-value="uuid"
            label="Subordinates"
            outlined
            dense
            clearable
            hide-details
          >
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0" class="me-1">{{ item.name }}</span>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ staffUuids.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-card-title>
      <v-data-table
        mobile-breakpoint="0"
        :headers="headers"
        :items="items.data"
        :items-per-page.sync="limit"
        :page.sync="page"
        :server-items-length="items.totalCount"
        :loading="loading"
        loading-text="Loading..."
      >
        <template v-slot:item.staff.first_name="{ item }">
          {{
            item.staff
              ? item.staff.first_name + " " + item.staff.last_name
              : "Not assign"
          }}
        </template>

        <!-- <template
          v-slot:item.actions="{ item }"
          v-if="
            $_checkPermission(
              this.auth.permissions,
              'Manage Vehicle Fuel',
              'Delete'
            )
          "
        >
          <v-btn
            depressed
            small
            icon
            color="error"
            class="mx-0 px-0 mt-1"
            dark
            @click="
              selectedItem = item;
              deleteDialog = true;
            "
          >
            <v-icon small> mdi-trash-can-outline </v-icon>
          </v-btn>
        </template> -->
      </v-data-table>
    </v-card>

    <!-- Delete dialog -->
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="290"
      v-if="selectedItem"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this vehicle fuel record? This action
          cannot be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteVehicleFuelX(selectedItem)"
            :loading="deleteLoading"
          >
            Delete
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { deleteVehicleFuel } from "@/services/vehicleFuelService";

import { fetchAllVehicleFuel } from "@/services/logsService";

import {
  fetchAndStoreStaffData,
  fetchAndStoreVehiclesData,
} from "@/services/preloadDataService";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    limit: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    page: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    startDate: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    endDate: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    vehicleUuids: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
    staffUuids: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
  },
  data: () => ({
    menu1: false,
    menu2: false,
    tab: 3,
    headers: [
      {
        text: "Id",
        sortable: false,
        value: "id",
      },
      {
        text: "Date",
        sortable: false,
        value: "date",
      },
      { text: "Vehicle No", value: "vehicles.vehicle_no", sortable: false },
      { text: "Driver", value: "staff.first_name", sortable: false },
      {
        text: "Meter Reading (km)",
        value: "meter_reading_km",
        sortable: false,
      },
      { text: "Fuel Type", value: "type", sortable: false },
      { text: "Quantity (L)", value: "quantity", sortable: false },
      { text: "Amount (LKR)", value: "amount", sortable: false },
      { text: "Remarks", value: "remarks", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    items: [],
    page: 1,
    limit: 10,
    startDate: "",
    endDate: "",
    vehicleUuids: [],
    staffUuids: [],
    dataLoading: false,
    loading: false,
    deleteDialog: false,
    deleteLoading: false,
    selectedItem: null,
    vehicles: [],
    staff: [],
  }),
  async created() {
    this.dataLoading = true;
    this.vehicles = await fetchAndStoreVehiclesData();
    this.staff = await fetchAndStoreStaffData();
    this.staff.forEach((person) => {
      person["name"] = person.first_name + " " + person.last_name;
    });
    await this.fetchLogs();
    this.dataLoading = false;
  },
  mounted() {
    //
  },
  methods: {
    changeView() {
      if (this.tab == 1) {
        this.$router.push({
          name: "PageMyVehicleFuelList",
        });
      }
      if (this.tab == 2) {
        this.$router.push({
          name: "PageAllVehicleFuelList",
        });
      }
    },
    async fetchLogs() {
      if (
        this.$_checkPermission(
          this.auth.permissions,
          "Manage Vehicle Fuel",
          "Read Sub"
        )
      ) {
        this.loading = true;
        if (this.$_getSubordinates(this.staff, this.auth.uuid).length > 0) {
          if (this.staffUuids.length == 0) {
            this.staffUuids = this.$_getSubordinates(
              this.staff,
              this.auth.uuid
            );
            const filteredArray = this.staff.filter((item) =>
              this.staffUuids.includes(item.uuid)
            );
            this.staff = filteredArray;
          }
        } else {
          this.staff = [];
        }
        if (this.staffUuids.length > 0) {
          this.items = await fetchAllVehicleFuel(
            this.page,
            this.limit,
            this.startDate ? this.startDate : "",
            this.endDate ? this.endDate : "",
            JSON.stringify(this.vehicleUuids),
            JSON.stringify(this.staffUuids),
            JSON.stringify(this.projectUuids)
          );
        } else {
          this.items = [];
        }
        this.loading = false;
      } else {
        this.$router.push({
          name: "PageDashboard",
        });
      }
    },
    async deleteVehicleFuelX(item) {
      this.deleteLoading = true;
      let deleteFuelReturn = await deleteVehicleFuel(item.vehicle_uuid, {
        uuid: item.uuid,
      });
      this.deleteLoading = false;

      if (deleteFuelReturn == "success") {
        this.deleteDialog = false;
        await this.fetchLogs();
      }
    },
  },
};
</script>